import React from "react";
import { Link } from "react-router-dom";
import "../assets/award/style.css"; // Ensure path accuracy
import Header from "../Components/Header";
import Footer from "./Footer";

const OurTeam = () => {
  return (
    <>
      <Header />
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Our Team</h2>
              <ol className="breadcrumb header-breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/" className="text-white">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Our Team
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      
        <div className="py-5 team4">
          <div className="container">
            <div className="row justify-content-center mb-4">
              <div className="col-md-7 text-center">
                <h3 className="mb-3">Experienced & Professional Team</h3>
              </div>
            </div>
            <div className="row">
              {/** Team Members Section **/}
              {[
                {
                  name: "Michael Doe",
                  title: "Property Specialist",
                  image:
                    "https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t1.jpg",
                  description:
                    "You can rely on our amazing features list and enjoy a great experience.",
                    urls:
                    "https://netsparked.com/our-team/manoj-kumar",
                },
                {
                  name: "Sarah Johnson",
                  title: "Marketing Expert",
                  image:
                    "https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t2.jpg",
                  description:
                    "You can rely on our amazing features list and enjoy a great experience.",
                    urls:
                    "https://netsparked.com/our-team/manoj-kumar",
                },
                {
                  name: "Tom Richards",
                  title: "Design Specialist",
                  image:
                    "https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t3.jpg",
                  description:
                    "You can rely on our amazing features list and enjoy a great experience.",
                    urls:
                    "https://netsparked.com/our-team/manoj-kumar",
                },
                {
                  name: "Anna Scott",
                  title: "Lead Developer",
                  image:
                    "https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg",
                  description:
                    "You can rely on our amazing features list and enjoy a great experience.",
                    urls:
                    "https://netsparked.com/our-team/manoj-kumar",
                },
                {
                    name: "Anna Scott",
                    title: "Lead Developer",
                    image:
                      "https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg",
                    description:
                      "You can rely on our amazing features list and enjoy a great experience.",
                      urls:
                      "https://netsparked.com/our-team/manoj-kumar",
                  },
                  {
                    name: "Anna Scott",
                    title: "Lead Developer",
                    image:
                      "https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg",
                    description:
                      "You can rely on our amazing features list and enjoy a great experience.",
                      urls:
                      "https://netsparked.com/our-team/manoj-kumar",
                  },
                  {
                    name: "Anna Scott",
                    title: "Lead Developer",
                    image:
                      "https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg",
                    description:
                      "You can rely on our amazing features list and enjoy a great experience.",
                      urls:
                      "https://netsparked.com/our-team/manoj-kumar",
                  },
                  {
                    name: "Anna Scott",
                    title: "Lead Developer",
                    image:
                      "https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg",
                    description:
                      "You can rely on our amazing features list and enjoy a great experience.",
                      urls:
                      "https://netsparked.com/our-team/manoj-kumar",
                  },
              ].map((teamMember, index) => (
                <div className="col-lg-3 mb-4" key={index}>
                  <div className="row">
                  <div className="top-main">
                    <a href={teamMember.urls} >
                    <div className="col-md-12">
                      <img
                        src={teamMember.image}
                        alt={`${teamMember.name}`}
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <div className="col-md-12 text-center">
                      <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">
                          {teamMember.name}
                        </h5>
                        <h6 className="subtitle mb-3">{teamMember.title}</h6>
                        <p>{teamMember.description}</p>
                      </div>
                    </div>
                    </a>
                  </div>
                </div>
                </div>
              ))}
            </div>
          </div>
        </div>
     

      <Footer />
    </>
  );
};

export default OurTeam;
