import React, { useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import Header from "../Components/Header";
import Footer from "./Footer";

const QRCodeGenerator = () => {
  const url = "https://netsparked.com/our-teams";
  const qrCodeRef = useRef(); // Reference to access the QR code canvas

  // Function to download the QR code
  const downloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector("canvas"); // Get the canvas element
    const image = canvas.toDataURL("image/png"); // Convert canvas to image
    const link = document.createElement("a");
    link.href = image;
    link.download = "qr-code.png"; // Set the default file name
    link.click(); // Trigger the download
  };

  return (
    <>
    <Header />
   
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>QR Code for Our Teams Page</h1>
      <div ref={qrCodeRef}>
        <QRCodeCanvas
          value={url}
          size={256} // Size in pixels
          level="H" // Error correction level: L, M, Q, H
          includeMargin={true}
        />
      </div>
      <button
        onClick={downloadQRCode}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
          backgroundColor: "#ec1b23",
          color: "#FFF",
          border: "none",
          borderRadius: "5px",
        }}
      >
        Download QR Code
      </button>
    </div><br /><br /><br />
    <Footer />
    </>
  );
};

export default QRCodeGenerator;
